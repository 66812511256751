import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Countdown from 'react-countdown';
import ReactDOM from 'react-dom';
import Supply from './Components/totalsupply'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  font-size: 20px;
  background-color: #6488cb;
  padding: 10px;
  font-weight: bold;
  color: #FFFFFF;
  width: 100px;
  cursor: pointer;
  box-shadow: -3px 3px 0px 1px #1b1b1b;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #6488cb;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  box-shadow: -3px 3px 0px 1px #1b1b1b;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Reserve seu camarote 👑`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "0x40Bb9EbBec977a8181d81eFa65f247DE247C52F0",
    SCAN_LINK: "https://etherscan.io/address/0x40bb9ebbec977a8181d81efa65f247de247c52f0",
    NETWORK: {
      NAME: "ETHEREUM",
      SYMBOL: "ETH",
      ID: 1,
    },
    NFT_NAME: "ViralataFunk",
    SYMBOL: "VFCB",
    MAX_SUPPLY: 10000,
    WEI_COST: 100000000000,
    DISPLAY_COST: 0.00,
    MARKETPLACE: "OpenSea",
    MARKETPLACE_LINK: "https://opensea.io/collection/viralatafunkcamarotebonde",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Mintando ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Classe social insuficiente.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Sua entrada no Camarote Bonde está garantida.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    
        <ResponsiveWrapper flex={1} test style={{
          padding: 0,
          maxWidth: "100%",
        }}>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "",
              padding: 0,
              borderRadius: 24,
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 60,
                color: "var(--accent-text)",
                fontFamily: "Beachday",
                display: "flex",
              }}
            >
              <Supply/>/{CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                fontSize: 12,
              }}
            >
              <StyledLink style={{ textAlign: "center", color: "#f0f8ff" }} target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerLarge />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Ingressos esgotados.
                </s.TextTitle>
                <s.SpacerMedium />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Veja o seu {CONFIG.NFT_NAME} no
                </s.TextDescription>
                <StyledLink style={{ textAlign: "center", fontSize: 35, }} target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "11px", }}
                >
                 Você pode mintar até 10 {CONFIG.SYMBOL}s de uma única vez! Eles são 100% gratuitos.
                </s.TextTitle>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                                <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Conectar à rede {CONFIG.NETWORK.NAME}.
                    </s.TextDescription>
                    <s.SpacerLarge />
                    <StyledButton style={{backgroundColor: "#6488cb", "boxShadow":"-3px 3px 0px 1px #1b1b1b", "fontSize":"12px", "Width":"100px","color": "white"}}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONECTAR
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"} >
                      <StyledButton 
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "..." : "MINT"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        
  );
} 



function padWithZeros(number, minLength) {
  const  numberString = number.toString();
  if(numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <div id="countdown" style={{"fontSize":"30px","alignItems":"center","justifyContent":"center","display":"flex","flexDirection":"column","padding":"0px 0px", "margin":"0px"}}>
      
      <div id="box-date" style={{"display":"flex","flexDirection":"column","borderRadius":"15px", "padding":"0px 20px", "margin":"5px"}}>
        <span id="minting-title" style={{"fontSize":"165%", "color":"white", "font-family":"Beachday", "padding-top":"9px"}}>ABERTURA DO CAMAROTE</span>
      </div>


      <div id="timer" classname="timer" style={{"display":"flex","maxWidth":"100%","flexDirection":"row", "margin":"15px", "font-size": "18px","font-family":"Beachday"}}>
        <div class="timer-pair" style={{"display":"flex","flexDirection":"column","backgroundColor":"#ffffff","borderRadius":"15px","marginLeft":"5px","marginRight":"5px","width":"50px","height":"55px", "padding":"8px", "color":"#597dc4"}}>
          <span id="two-numbers">{padWithZeros(days, 2)}</span>
          <span>d</span>
        </div>
        <div class="timer-pair" style={{"display":"flex","flexDirection":"column","backgroundColor":"#ffffff","borderRadius":"15px","marginLeft":"5px","marginRight":"5px","width":"50px", "height":"55px", "padding":"8px", "color":"#597dc4"}}>
          <span id="two-numbers">{padWithZeros(hours, 2)}</span>
          <span>h</span>
        </div>
        <div class="timer-pair" style={{"display":"flex","flexDirection":"column","backgroundColor":"#ffffff","borderRadius":"15px","marginLeft":"5px","marginRight":"5px","width":"50px", "height":"55px", "padding":"8px", "color":"#597dc4"}}>
            <span id="two-numbers">{padWithZeros(minutes, 2)}</span>
            <span>m</span>
          </div>
          <div class="timer-pair" style={{"display":"flex","flexDirection":"column","backgroundColor":"#ffffff","borderRadius":"15px","marginLeft":"5px","marginRight":"5px","width":"50px", "height":"55px", "padding":"8px", "color":"#597dc4"}}>
          <span id="two-numbers">{padWithZeros(seconds, 2)}</span>
            <span>s</span>
        </div>
      </div>

    </div>
  }
};

ReactDOM.render(
  <Countdown
    date={Date.now() + 2653818400000 - Date.now()}
    renderer={renderer}
  />,
  document.getElementById('countdown')
);

  // Random component
  const Completionist = () => <div style={{"fontSize":"30%", "display":"flex","flexDirection":"column", "borderRadius":"15px","marginLeft":"5px", "backgroundColor":"#ffffff", "marginRight":"5px","padding":"10px 20px"}}>Aguarde. Estamos fazendo os últimos ajustes no contrato para você mintar o seu ViralataFunk.</div>;


export default App;


